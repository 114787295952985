$(document).ready(function() {
    function t(t) {
        return t = t.replace(/((https?|s?ftp|ssh)\:\/\/[^"\s\<\>]*[^.,;'">\:\s\<\>\)\]\!])/g, function(t) {
            return '<a href="' + t + '"  target="_blank">' + t + "</a>"
        }), t = t.replace(/\B@([_a-z0-9]+)/gi, function(t) {
            return '<a href="http://twitter.com/' + t.substring(1) + '" style="font-weight:lighter;" target="_blank">' + t.charAt(0) + t.substring(1) + "</a>"
        }), t = t.replace(/\B#([_a-z0-9]+)/gi, function(t) {
            return '<a href="https://twitter.com/search?q=' + t.substring(1) + '" style="font-weight:lighter;" target="_blank">' + t.charAt(0) + t.substring(1) + "</a>"
        })
    }

    function e(t) {
        var e = t.split(" ");
        t = e[1] + " " + e[2] + ", " + e[5] + " " + e[3];
        var r = Date.parse(t),
            i = arguments.length > 1 ? arguments[1] : new Date,
            a = parseInt((i.getTime() - r) / 1e3),
            n = t.substr(4, 2) + " " + t.substr(0, 3);
        return a += 60 * i.getTimezoneOffset(), 60 > a ? "1m" : 120 > a ? "1m" : 3600 > a ? parseInt(a / 60).toString() + "m" : 7200 > a ? "1h" : 86400 > a ? parseInt(a / 3600).toString() + "h" : 172800 > a ? n : n
    }
    var r = 10,
        i = !0,
        a = !0,
        n = !0,
        s = !0,
        o = !0,
        c = "",
        u = "";
    $("#twitter-feed").html(c + u), $.getJSON("/twitterapi.php", function(u) {
        for (var d = "", w = 1, h = 0; h < u.length; h++) {
            var p = u[h].user.name,
                l = u[h].user.screen_name,
                g = u[h].user.profile_image_url_https,
                f = u[h].text,
                m = !1,
                _ = !1,
                v = u[h].id_str;
            "undefined" != typeof u[h].retweeted_status && (g = u[h].retweeted_status.user.profile_image_url_https, p = u[h].retweeted_status.user.name, l = u[h].retweeted_status.user.screen_name, v = u[h].retweeted_status.id_str, f = u[h].retweeted_status.text, m = !0), "@" == u[h].text.substr(0, 1) && (_ = !0), (1 == a || 0 == m && 0 == a) && (1 == i || 0 == i && 0 == _) && u[h].text.length > 1 && r >= w && (1 == n && (f = t(f)), 1 == w && (d += c), d += '<div class="twitter-article" id="tw' + w + '">', d += '<div class="twitter-pic"><a href="https://twitter.com/' + l + '" target="_blank"><img src="' + g + '"images/twitter-feed-icon.png" width="36" height="36" alt="twitter icon" /></a></div>', d += '<div id="twitter-actions"><div class="intent" id="intent-reply"><a href="https://twitter.com/intent/tweet?in_reply_to=' + v + '" title="Reply"></a></div><div class="intent" id="intent-retweet"><a href="https://twitter.com/intent/retweet?tweet_id=' + v + '" title="Retweet"></a></div><div class="intent" id="intent-fave"><a href="https://twitter.com/intent/favorite?tweet_id=' + v + '" title="Favourite"></a></div></div>', d += '<div class="twitter-text"><p><span class="tweetprofilelink"><strong><a href="https://twitter.com/' + l + '" target="_blank">' + p + '</a></strong> <a class="handle" href="https://twitter.com/' + l + '" target="_blank">@' + l + '</a></span><span class="tweet-time"><a href="https://twitter.com/' + l + "/status/" + v + '" target="_blank">' + e(u[h].created_at) + "</a></span><br/><span class='copy'>" + f + "</span></p>", 1 == m && 1 == o && (d += '<div id="retweet-indicator"></div>'), d += "</div>", d += "</div>", w++)
        }
        $("#twitter-feed").html(d), 1 == s && $("#twitter-actions a").click(function() {
            var t = $(this).attr("href");
            return window.open(t, "tweet action window", "width=580,height=500"), !1
        })
    }).error(function(t, e, r) {
        var i = "";
        i = 0 === t.status ? "Connection problem. Check file path and www vs non-www in getJSON request" : 404 == t.status ? "Requested page not found. [404]" : 500 == t.status ? "Internal Server Error [500]." : "parsererror" === exception ? "Requested JSON parse failed." : "timeout" === exception ? "Time out error." : "abort" === exception ? "Ajax request aborted." : "Uncaught Error.\n" + t.responseText
    })
});
